import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import tw from '@tailwindcssinjs/macro'
import {
  DISABLE_CHAT,
  ENABLE_CHAT,
  SET_CHATTAB,
  SET_HOSTNAME,
  SET_POINTER,
  SET_SECONDARYCHATTAB,
  SET_USERNAME,
  TOGGLE_CHAT,
} from 'actions/types'
import Icon from 'components/Icon'
import ToggleButton from 'components/ToggleButton'
// import shallow from 'zustand/shallow'
import { Picker } from 'emoji-mart'
import useFileUpload from 'hooks/useFileUpload'
import useMqttConnection, { IDLE } from 'hooks/useMQTT'
import Attachment from 'img/attachment.svg'
import EmojiIcon from 'img/emoji.svg'
import Send from 'img/send.svg'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { useDropzone } from 'react-dropzone'
import { QRCode } from 'react-qr-svg'
import { useDispatch, useSelector } from 'react-redux'

import ChatIconComponent from './ChatIcon'
import ChatMessageItem from './ChatMessageItem'
import ChatTabIcon from './ChatTabIcon'
import Notifications from './Notifications'
import Page from './Page'

// import { decrement, increment, reset } from '../actions/counterActions'

const maxMessages = 50
const cooldownTime = 2
const staminaActive = false
const secondaryTopic = false

function createUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const useChat = () => {
  const username = useSelector((state) => state.username)
  const chatOpen = useSelector((state) => state.chatOpen)
  const chatDisabled = useSelector((state) => state.chatDisabled)
  const currentColor = useSelector((state) => state.currentColor)
  const currentChatTab = useSelector((state) => state.currentChatTab)
  const secondaryChatTab = useSelector((state) => state.secondaryChatTab)
  const currentPage = useSelector((state) => state.currentPage)
  const domain = useSelector((state) => state.hostname)
  const accessToken = useSelector((state) => state.accessToken)

  const pointer = useSelector((state) => state.pointer)
  const notificationsDisabled = useSelector((state) => state.notificationsDisabled)
  const dispatch = useDispatch()

  const setPointer = (state) => {
    dispatch({
      type: SET_POINTER,
      payload: state,
    })
  }
  const toggleChat = () =>
    dispatch({
      type: TOGGLE_CHAT,
    })
  const disableChat = () => {
    dispatch({
      type: DISABLE_CHAT,
    })
  }

  const setCurrentChatTab = (tab) => {
    dispatch({
      type: SET_CHATTAB,
      payload: tab,
    })
  }

  const enableChat = () => {
    {
      dispatch({
        type: ENABLE_CHAT,
      })
    }
  }
  const setSecondaryChatTab = (tab) => {
    dispatch({
      type: SET_SECONDARYCHATTAB,
      payload: tab,
    })
  }

  const setUsername = (_username) => {
    dispatch({
      type: SET_USERNAME,
      payload: _username,
    })
  }
  const setHostname = (data) => {
    dispatch({
      type: SET_HOSTNAME,
      payload: data,
    })
  }

  return {
    username,
    setUsername,
    setHostname,
    toggleChat,
    chatOpen,
    chatDisabled,
    currentColor,
    enableChat,
    disableChat,
    currentChatTab,
    setCurrentChatTab,
    domain,
    pointer,
    setPointer,
    setSecondaryChatTab,
    secondaryChatTab,
    currentPage,
    notificationsDisabled,
    accessToken,
  }
}

export default function Chat() {
  const [_answers, _setAnswers] = useState([])
  const [textInput, setTextInput] = useState('')
  const [_chatDisabled, _setChatDisabled] = useState(false)
  const [sendDisabled, setSendDisabled] = useState(false)
  const [staminaState, setStaminaState] = useState(0)
  const [stamina, setStamina] = useState(null)
  const [_username, _setUsername] = useState('')
  const [globalTab, setGlobalTab] = useState(true)
  const [secondaryTab, setSecondaryTab] = useState(false)
  const [showPicker, setShowPicker] = useState(false)
  const { sendForm, setSelectedFile, selectedFile, onDropHandler, setDroppedFile, imageURL } = useFileUpload()
  const [_selectedFile, _setSelectedFile] = useState(null)
  const [startHealing, setStartHealing] = useState(false)
  const [drag, setDrag] = useState(false)
  const [scrolling, setScrolling] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)
  const [openFileUpload, setOpenfileUpload] = useState(false)
  const [queryURL, setQueryURL] = useState('')
  const [scrollTopMax, setScrollTopMax] = useState(0)

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, open } = useDropzone({
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: (files) => {
      console.log(files)
      onDropHandler(files).then((res) => {
        sendUserMqttMessage({ msg: res, usr: username, type: 'image' }, currentChatTab)
      })
      setDrag(false)
      setOpenfileUpload(false)
    },
    onDropRejected: (files) => {
      if (files.length > 0) {
        files.forEach((item) => {
          // Display Error Message to user
          item.errors.forEach((err) => console.error(err.message))
        })
      }
      setDrag(false)
    },
    onDrop: (files, rejections, e) => {
      e.preventDefault()
      e.stopPropagation()
      setDrag(false)
      setPointer(false)
    },
    accept: 'image/*',
    noDragEventsBubbling: true,
  })
  const {
    chatOpen,
    toggleChat,
    chatDisabled,
    username,
    setUsername,
    setHostname,
    domain,
    currentColor,
    setCurrentChatTab,
    currentChatTab,
    notificationsDisabled,
    setSecondaryChatTab,
    secondaryChatTab,
    setPointer,
    currentPage,
    disableChat,
    enableChat,
    accessToken,
  } = useChat()
  const messagesEndRef = useRef(null)
  const answerListRef = useRef(null)
  const [fullChat, setFullChat] = useState(false)

  const router = useRouter()

  const uuid = createUUID()
  // const domain = router.
  const joinConnection = useMqttConnection({ uuid, domain })

  const {
    connect,
    connectionState,
    sendUserMqttMessage,
    globalAnswers,
    karaokeAnswers,
    livestreamAnswers,
    gameAnswers,
    sendNotification,
    notifications,
    setNotifications,
    sendRetainedMessage,
  } = joinConnection

  const inputField = useRef()

  // set hostname in store
  useEffect(() => {
    let host = window.location.hostname
    setHostname(host)
  })
  // handles when to connect to mqtt
  useEffect(() => {
    // do not connect if already connected
    if (connectionState !== IDLE) return
    // do not connect if in these stages
    // if ([PRELOGIN, LOGIN, DISCLAIMER].includes(joinStage)) return

    connect()
  }, [connectionState, connect])

  useEffect(() => {
    if (router.pathname === '/chat') {
      setFullChat(true)
      enableChat()
    } else {
      setFullChat(false)
    }

    if (router.pathname === '/login') {
      _setChatDisabled(true)
    } else {
      _setChatDisabled(false)
    }
  }, [router, enableChat])

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  // make emojis bigger
  function replaceEmojis(string) {
    var exp = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
    var regex = new RegExp(exp)
    let res = string.match(regex)
    console.log(res)
    return string.replace(exp, (i) => {
      return `<span class="largerEmoji">${i}</span>`
    })
  }

  var sendText = useCallback(
    async (e) => {
      if (/^\/name/i.test(textInput)) {
        let oldUsername = (username || 'No Username').toString()
        let newUsername = textInput.slice(6)
        let msg = 'User ' + oldUsername + ' renamed to ' + newUsername
        setUsername(newUsername)
        if (staminaActive) {
          setStaminaState(staminaState + 1)
        }
        sendUserMqttMessage({ msg: msg, usr: newUsername, type: 'text' }, currentChatTab)
      } else if (/^\/notify/i.test(textInput)) {
        let msg = textInput.slice(8)
        var expression = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?) (.*)/
        var regex = new RegExp(expression)
        var t = msg
        let regTest = t.match(regex)
        if (regTest) {
          let desc = regTest[5]
          let link = regTest[2]
          sendNotification({ msg: desc, link: link, id: createUUID(), type: 'link' })
        } else {
          sendNotification({ msg: msg, id: createUUID(), type: 'text' })
        }
      } else if (/^\/chat/i.test(textInput)) {
        let msg = textInput.slice(6)
        console.log('chat control')
        if (msg === 'on') {
          // display Chat
          console.log('on')
          sendRetainedMessage({ payload: false }, 'disableChat', { retain: true })
        } else if (msg === 'off') {
          // disable Chat
          console.log('off')
          sendRetainedMessage({ payload: true }, 'disableChat', { retain: true })
        }
      } else if (textInput !== '') {
        // setAnswers((arr) => [...arr, textInput])
        if (staminaActive) {
          setStaminaState(staminaState + 1)
        }
        let text = replaceEmojis(textInput)
        sendUserMqttMessage({ msg: text, usr: username, type: 'text' }, currentChatTab)
        setShowPicker(false)
        setStartHealing(false)
        staminaHealing()
        goToBottom()
        if (inputField) inputField.current.style.height = '40px'
      } else if (selectedFile.length === 1) {
        sendForm(e)
          .then((res) => {
            if (staminaActive) {
              setStaminaState(staminaState + 1)
            }
            goToBottom()
            sendUserMqttMessage({ msg: res, usr: username, type: 'image' }, currentChatTab)
            setStartHealing(false)
          })
          .catch((err) => console.error(err))
      } else {
        return
      }

      setTextInput('')
      // e.preventDefault()
    },
    [
      goToBottom,
      sendRetainedMessage,
      sendNotification,
      textInput,
      setStaminaState,
      staminaState,
      sendUserMqttMessage,
      username,
      selectedFile,
      sendForm,
      currentChatTab,
      setUsername,
      staminaHealing,
    ]
  )

  const checkEnter = useCallback(
    (e) => {
      if (e.keyCode === 13 && e.shiftKey) {
        // e.preventDefault()
        // let start = e.target.selectionStart,
        //   end = e.target.selectionEnd
        // setTextInput(textInput.substring(0, start) + '\n' + textInput.substring(end))
        // inputField.current.selectionStart = inputField.current.selectionEnd = start + 1
      } else if (e.keyCode === 13 && !sendDisabled) {
        // block enter
        sendText(e)
        e.preventDefault()
      }
    },
    [sendText, sendDisabled]
  )

  useEffect(() => {
    if (staminaState >= maxMessages) {
      cooldown()
    }
  }, [cooldown, staminaState])

  // keep messages at bottom
  useLayoutEffect(() => {
    if (answerListRef.current) {
      if (!scrolling) {
        // scroll to bottom for firefox and Chrome
        if (answerListRef.current) {
          console.log(scrollTopMax)
          answerListRef.current.scrollTo(0, scrollTop)
        }
      }
    }
  }, [_answers, scrolling, scrollTop, scrollTopMax])

  useLayoutEffect(() => {
    let answerRef = answerListRef
    if (answerRef.current === null) return
    setScrollTopMax(answerRef.current.scrollTop + answerRef.current.clientHeight)
    setScrollTop(answerRef.current.scrollHeight)
  }, [_answers, answerListRef])

  useLayoutEffect(() => {
    let answerRef = answerListRef
    if (answerRef.current === null) return
    const onScroll = (e) => {
      setScrollTop(e.target.scrollHeight)
      // setScrollTopMax(e.target.scrollTop + e.target.clientHeight)
      setScrolling(e.target.scrollTop + e.target.clientHeight < e.target.scrollHeight)
    }
    answerRef.current.addEventListener('scroll', onScroll)

    return () => {
      if (answerRef.current !== null) answerRef.current.removeEventListener('scroll', onScroll)
    }
  }, [_answers, scrollTop, answerListRef])

  const goToBottom = useCallback(() => {
    if (answerListRef.current) {
      answerListRef.current.scrollTo(0, scrollTop)
    }
  }, [answerListRef, scrollTop])

  // useLayoutEffect(() => {
  //   var searchTimeout
  //   if (inputField)
  //     inputField.current.onkeypress = function () {
  //       if (searchTimeout !== undefined) clearTimeout(searchTimeout)
  //       searchTimeout = setTimeout(callServerScript, 5000)
  //     }
  // })

  // function callServerScript() {
  //   // your code here
  // }
  const cooldown = useCallback(() => {
    setStamina(true)
    setSendDisabled(true)

    setTimeout(() => {
      setStaminaState(0)
      setStamina(false)
      setSendDisabled(false)
    }, cooldownTime * 1000)
  }, [setSendDisabled, setStamina, setStaminaState])

  // let running = useRef()
  let staminaHealing = useCallback(() => {
    //   console.log('trigger')
    //   if (!running.current ) {
    //     running.current = setInterval(() => setStaminaState(staminaState - 1), 5000)
    //   }
    //   // let int
    //   // if (!startHealing) {
    //   //   if (int !== undefined) clearInterval(int)
    //   //   if (staminaState > 0) {
    //   //     int = setInterval(() => {
    //   //       setStaminaState(staminaState - 1)
    //   //     }, 5000)
    //   //     setStartHealing(false)
    //   //   } else {
    //   //     clearInterval(int)
    //   //   }
    //   // }
  }, [])

  const chatInputHandler = useCallback(
    (e) => {
      e.target.value = e.target.value.replace(/[\r\n\v]+/g, '')
      setTextInput(e.target.value)
    },
    [setTextInput]
  )

  const closeNotification = (e) => {
    setNotifications(notifications.filter((item) => item.id !== e.currentTarget.id))
  }

  useEffect(() => {
    let answerPages = {
      ['global']: globalAnswers,
      ['livestream']: livestreamAnswers,
      ['game']: gameAnswers,
      ['karaoke-recorder']: karaokeAnswers,
    }
    if (currentChatTab) _setAnswers(answerPages[currentChatTab])
  }, [currentChatTab, globalAnswers, livestreamAnswers, gameAnswers, karaokeAnswers])

  const setGlobal = useCallback(() => {
    setSecondaryTab(false)
    setGlobalTab(true)
    setCurrentChatTab('global')
  }, [setSecondaryTab, setGlobalTab, setCurrentChatTab])

  const setSecondary = useCallback(() => {
    setSecondaryTab(true)
    setGlobalTab(false)
    setCurrentChatTab(currentPage)
  }, [setSecondaryTab, setGlobalTab, setCurrentChatTab, currentPage])

  const handleEmojiSelect = useCallback(
    (e) => {
      if (e.native) setTextInput(textInput + e.native)
      if (inputField) inputField.current.focus()
      setShowPicker(false)
    },
    [inputField, textInput]
  )

  // auto scale text area
  useLayoutEffect(() => {
    if (inputField.current) inputField.current.style.height = inputField.current.scrollHeight + 'px'
  })
  const files = acceptedFiles.map((file) => (
    <div css={tw`flex flex-col`} key={file.path}>
      <p css={tw`font-bold text-base`}>{file.path}</p>
      <p css={tw`text-sm`}>{file.size} bytes</p>
    </div>
  ))

  function encodeQueryData(data) {
    const ret = []
    for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    return ret.join('&')
  }
  const handleOpenFile = useCallback(() => {
    setOpenfileUpload(!openFileUpload)
    let data = { user: username, topic: currentChatTab, accessToken: accessToken }
    let queryURL = window.location.origin + '/mobile-upload?' + encodeQueryData(data)
    setQueryURL(queryURL)
  }, [setOpenfileUpload, openFileUpload, currentChatTab, username, accessToken])

  useEffect(() => {
    // document.addEventListener(
    //   'dragenter',
    //   function (e) {
    //     console.log(e)
    //     e.preventDefault()
    //     e.stopImmediatePropagation()
    //     e.dataTransfer.effectAllowed = 'none'
    //     e.dataTransfer.dropEffect = 'none'
    //     setDrag(true)
    //   },
    //   false
    // )
    // document.addEventListener('dragover', function (e) {
    //   e.preventDefault()
    //   e.stopImmediatePropagation()
    //   e.dataTransfer.effectAllowed = 'none'
    //   e.dataTransfer.dropEffect = 'none'
    //   setDrag(true)
    // })
    document.addEventListener('drop', function (e) {
      e.preventDefault()
      e.stopImmediatePropagation()
      e.dataTransfer.effectAllowed = 'none'
      e.dataTransfer.dropEffect = 'none'
      setDrag(false)
    })
    document.addEventListener('dragleave', function (e) {
      e.preventDefault()
      e.stopImmediatePropagation()
      e.dataTransfer.effectAllowed = 'none'
      e.dataTransfer.dropEffect = 'none'
      setDrag(false)
    })
  })
  return (
    <div>
      {!chatDisabled && !_chatDisabled && (
        <div
          onDragOver={(e) => {
            setDrag(true)
            setPointer(true)
          }}
          onDragLeave={(e) => {
            setDrag(false)
            setPointer(false)
          }}
          onDrop={(e) => {
            setDrag(false)
            setPointer(false)
          }}
        >
          <ProppedChatWindow open={chatOpen} fullScreen={fullChat}>
            {!fullChat && (
              <ChatHeader>
                <ChatHeaderGroupWrapper>
                  {secondaryTopic && (
                    <>
                      <ChatTabIcon
                        activeState={globalTab ? true : false}
                        onClick={setGlobal}
                        icon="globe"
                      ></ChatTabIcon>
                      {currentPage && (
                        <ChatTabIcon
                          activeState={secondaryTab ? true : false}
                          onClick={setSecondary}
                          icon="party"
                        ></ChatTabIcon>
                      )}
                    </>
                  )}
                </ChatHeaderGroupWrapper>
                <ChatCloseIcon onClick={toggleChat} src="../close.svg"></ChatCloseIcon>
              </ChatHeader>
            )}
            {drag && (
              <DropzoneWrapper className="container">
                <DropTrigger
                  {...getRootProps({ className: 'dropzone', isDragAccept, isDragActive, isDragReject })}
                  id="dropzone"
                >
                  <input {...getInputProps()} />
                  <p>Drag drop some files here, or click to select files</p>

                  <button
                    onClick={() => {
                      setDrag(false)
                    }}
                    css={tw`mt-4`}
                  >
                    Close Dialog
                  </button>
                </DropTrigger>
              </DropzoneWrapper>
            )}
            {!drag && (
              <>
                <div>
                  <Notifications onClick={closeNotification} notifications={notifications} />
                </div>
                <ChatActionContainer>
                  {openFileUpload && (
                    <StyledPage>
                      <BrowserView>
                        <h3>To upload a file drag and drop it right here.</h3>
                      </BrowserView>
                      <MobileView>
                        <a css={tw`font-bold text-black text-base`} href={queryURL} target="_blank" rel="noreferrer">
                          Click here to upload an image.
                        </a>
                      </MobileView>
                      <BrowserView>
                        <div
                          css={css`
                            text-align: center;
                            margin-bottom: 12px;
                          `}
                        >
                          <div>or</div>
                          <h3>
                            Scan the QR Code to <br />
                            upload an image from your phone.
                          </h3>
                        </div>
                        <QRCode bgColor="#FFFFFF" fgColor="#000000" level="Q" style={{ width: 256 }} value={queryURL} />
                      </BrowserView>
                    </StyledPage>
                  )}
                  <ChatAnswers id="chatAnswers">
                    <div className="answerList" ref={answerListRef}>
                      <ChatAnswersList>
                        {_answers &&
                          _answers.map((item) => <ChatMessageItem payload={item} key={createUUID()}></ChatMessageItem>)}
                      </ChatAnswersList>
                      <div ref={messagesEndRef} />
                    </div>
                  </ChatAnswers>
                  {scrolling && <ScrollNotice onClick={goToBottom}>Continue automatic scrolling</ScrollNotice>}
                </ChatActionContainer>
              </>
            )}

            <ChatInputField>
              {staminaActive && (
                <ChatStaminaWrapper>
                  <ChatStaminaBar
                    css={
                      stamina
                        ? css`
                            animation: ${animate} ${cooldownTime}s ease;
                          `
                        : ''
                    }
                    stamina={staminaState}
                  />
                </ChatStaminaWrapper>
              )}
              {/* {selectedFile.length > 0 && (
                <FileList>
                  {selectedFile.map((item) => (
                    <FileItemDetails key={item.name}>
                      <FileItem>
                        <ThumbInner>
                          <ThumbImage src={item.preview} />
                        </ThumbInner>
                      </FileItem>
                      <FileItemMeta>
                        <p css={tw`font-bold text-base`}>{item.name}</p>
                        <p css={tw`text-sm`}>{item.size}</p>
                      </FileItemMeta>
                    </FileItemDetails>
                  ))}
                </FileList>
              )} */}
              {showPicker ? (
                <PickerWrapper>
                  <Picker
                    title="Pick your Emoji..."
                    showPreview={false}
                    native={true}
                    color="#ff6900"
                    perLine={10}
                    onSelect={handleEmojiSelect}
                  />
                </PickerWrapper>
              ) : (
                <></>
              )}
              <ChatInputWrapper>
                <ChatButtonsWrapper>
                  <BrowserView>
                    <ToggleButton activeState={showPicker} onClick={() => setShowPicker(!showPicker)}>
                      <EmojiIcon />
                    </ToggleButton>
                  </BrowserView>
                  <ToggleButton activeState={openFileUpload} onClick={handleOpenFile}>
                    <Attachment />
                  </ToggleButton>
                </ChatButtonsWrapper>
                <ChatInput
                  type="text"
                  value={textInput}
                  onChange={chatInputHandler}
                  onKeyUp={checkEnter}
                  onFocus={() => setOpenfileUpload(false)}
                  ref={inputField}
                ></ChatInput>
                <ChatButtonsWrapper>
                  <SendButton onClick={sendText} disabled={sendDisabled ? true : false}>
                    <Icon>
                      <Send />
                    </Icon>
                  </SendButton>
                </ChatButtonsWrapper>
              </ChatInputWrapper>
            </ChatInputField>
          </ProppedChatWindow>
          <ProppedTabHeader
            onClick={toggleChat}
            color={currentColor}
            css={
              chatOpen
                ? css`
                    display: none;
                  `
                : css`
                    display: flex;
                    flex-direction: column;
                    height: auto;
                  `
            }
          >
            <Notifications css={tw`absolute`} onClick={closeNotification} notifications={notifications} />
            <ChatIconComponent />
          </ProppedTabHeader>
        </div>
      )}
      {!notificationsDisabled && (
        <>
          {chatDisabled && (
            <HiddenTabHeader>
              <Notifications bottom onClick={closeNotification} notifications={notifications} />
            </HiddenTabHeader>
          )}
        </>
      )}
    </div>
  )
}

let dynamicStyle = (props) =>
  css`
    color: ${props.color};
  `

let windowDynamic = (props) => {
  if (!props.open) {
    return css`
      display: none;
    `
  }
  if (props.fullScreen) {
    return css`
      width: 100vw !important;
      height: 100% !important;
      margin: 0;
    `
  }
}

const StyledPage = styled(Page)(tw`
  bg-white
  absolute
  z-50
`)

const DropTrigger = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 100%;
  justify-content: center;
`

const DropzoneWrapper = styled.section(tw`
  w-full
  h-full
  flex
  justify-center
  items-center
  overflow-hidden
`)
// const FileList = styled.div(tw`
//   h-auto
//   w-full
//   border
//   border-gray-400
//   bg-gray-200
//   p-2
//   mb-2
//   `)

// const FileItem = styled.div(tw`
//     flex
//     mr-4
//     w-40
//     h-auto
//   `)

// const FileItemDetails = styled.div(tw`
//     flex
//     w-full
//     `)
// const FileItemMeta = styled.div(tw`
//     flex
//     flex-col
//     w-full
//     `)
// const ThumbInner = styled.div(tw`
//     flex
//     rounded-md
//     border
//     border-solid
//     border-gray-300
//     min-w-0
//     overflow-hidden
//     p-2
//     `)
// const ThumbImage = styled.img(tw`
//   block
//   w-auto
//   h-auto
// `)
const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const ChatActionContainer = styled.div(tw`
 relative
  h-full
  overflow-hidden
`)
const ChatButtonsWrapper = styled.div(tw`
  flex
  items-end
  h-auto
`)
const PickerWrapper = styled.div(tw`
  bottom-0
  flex
  justify-center
  w-full
  mb-2
`)

const ChatHeaderGroupWrapper = styled.div(tw`
  flex
  flex-row
  h-full
`)
const ChatCloseIcon = styled.img(tw`
w-5
h-5
text-3xl
mx-2
flex
justify-center
items-center
cursor-pointer
`)

const ChatStaminaWrapper = styled.div(tw`
w-full
h-2
border
border-solid
border-gray-600
mb-2
`)
const ChatHeader = styled.div(
  tw`
  w-full
  h-12
  items-center
  justify-between
  flex
  overflow-hidden
  border-b
  border-gray-300
`,
  css`
    min-height: 3rem;
  `
)

const ChatTabHeader = styled.div(
  tw`
  w-92
  h-24
  items-center
  justify-center
  flex
  p-4
  cursor-pointer
  fixed
  right-0
  mx-8
  bottom-20

  bg-transparent

`,
  css`
    @media (max-width: 414px) {
      width: 100%;
      margin: 0;
    }
  `
)

const ProppedTabHeader = styled(ChatTabHeader)`
  ${dynamicStyle}
`

const HiddenTabHeader = styled(ChatTabHeader)(tw`
  cursor-default
`)
const ChatStaminaBar = styled.div`
    width: ${(props) => {
      let steps = 100 / maxMessages
      let curentStep = props.stamina * steps
      return curentStep + '%;'
    }}
    height: 100%;
    background-color: #000;
  `

const animate = keyframes`
    from{
      width: 100%
    }
    to {
      width: 0%
    }
  `
const ChatWindow = styled.div(
  tw`
  fixed
  h-3/5
  mt-8
  bottom-0
  flex-col
  bg-gray-500
  right-0
  w-92
  flex
  border
  border-gray-600
  bg-opacity-75
  overflow-hidden
  mr-8
`,
  css`
    min-height: 600px;
    @media (orientation: portrait) {
      @media (max-width: 414px) {
        right: 50%;
        width: 100%;
        margin: 0;
        min-height: 550px;
        transform: translateX(50%);
      }
    }
    @media (orientation: landscape) {
      @media (max-height: 640px) {
        min-height: 0;
        height: 95vh;
        margin: 0;
        width: 20rem;
      }
    }
  `
)

const ProppedChatWindow = styled(ChatWindow)`
  ${windowDynamic}
`

const ChatInputField = styled.div(tw`
  p-4

`)
const ChatInputWrapper = styled.div(tw`
  relative
  bottom-0
  flex
  w-full
`)
const ChatInput = styled.textarea(tw`
  w-full
  h-10
  text-lg
  p-2
  bg-gray-200
  focus:bg-white
  focus:outline-none
  focus:border-black
`)

const SendButton = styled.button`
  // margin: 8px;
  font-weight: bold;

  &:disabled {
    color: #777;
    cursor: not-allowed;
  }
`

const ChatAnswers = styled.div(
  tw`
  h-full
`,
  css`
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    // flex: 1 1 auto;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;

    .answerList {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      // justify-content: flex-end;
      // width: 100%;
    }

    // .answerList:first-of-type {
    //   margin-top: auto;
    //   height: 100%;
    // }
  `
)

const ChatAnswersList = styled.ul(
  tw`
items-end
flex
flex-wrap
w-full`,
  css`
    :first-of-type {
      margin-top: auto;
      // height: 100%;
    }
  `
)

const ScrollNotice = styled.button(
  tw`
    bottom-0
    w-full
    bg-gray-500
    text-white
    font-bold
    px-4
    absolute
`,
  css`
    line-height: 3rem;
    opacity: 0.9;
  `
)
